// Generic imports
import React from "react"
import styled from "styled-components"

// Other components imports

export const Styles = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  cursor: pointer;

  .checkbox {
    height: 25px;
    width: 25px;
    border: 4px #c4c4c4 solid;
    border-radius: 50%;
    position: relative;
  }

  .checkboxClicked {
    background-color: #efa51d;
    border-color: #efa51d;
  }

  .numberCheckbox {
    color: white;
    font-weight: 500;
    position: absolute;
    font-size: 14px;
    top: -1px;
    left: 0px;
    right: 0;
    bottom: 0;
  }

  .choice {
    margin: 10px;
    text-align: left;
    font-size: 14px;

    @media (max-width: 400px) {
      font-size: 12px;
    }
  }
`
const CheckBox = ({ number, onClickfunc, children }) => {
  return (
    <Styles onClick={onClickfunc}>
      <div>
        <div className={`checkbox ${number ? `checkboxClicked` : ""}`}>
          <div className="numberCheckbox">{number}</div>
        </div>
      </div>
      <div className="choice">{children}</div>
    </Styles>
  )
}

export default CheckBox
