import * as React from "react"
import Layout from "../../../components/layout"
import * as sessionSelectors from "../../../redux/slices/session/selectors"
import { connect } from "react-redux"
import { PersonalityTestStyles } from "../../../components/PersonalityTest/style"
import CheckBox from "../../../components/Reusable/Checkbox"
import ProgressBar from "@ramonak/react-progress-bar"
import { useState, useEffect } from "react"
import {
  getTestProgress,
  updateTestProgress,
} from "../../../services/personalityTest"
import { toast } from "react-toastify"
import Spinner from "../../../components/Reusable/Spinner"
import background from "../../../images/tes-kepribadian/backgroundTesKepribadian.png"
import Button from "../../../components/Reusable/Button"
import next from "../../../images/tes-kepribadian/next.svg"
import previous from "../../../images/tes-kepribadian/previous.svg"
import { navigate } from "gatsby"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const OngoingTestPage = ({ user, params }) => {
  const id = params.id
  const [progress, setProgress] = useState()
  const [answer, setAnswer] = useState([])
  const [trigger, setTrigger] = useState(false)

  const login = () => {
    toast("Anda perlu login terlebih dahulu")
    setTimeout(() => {
      navigate("/login")
    }, 1000)
  }

  useEffect(() => {
    const getProgress = async () => {
      const progressResp = await getTestProgress(id)
      if (progressResp.data.finished === true) {
        toast.success("Kamu sudah menyelesaikan kuisnya!")
        setTimeout(() => {
          navigate(`/personality-test/`)
        }, 1000)
      } else {
        setProgress(progressResp.data)
      }
    }

    if (user?.email) {
      getProgress()
    } else {
      login()
    }
  }, [trigger])

  const handleOnClick = choice => {
    choice = choice + 1
    if (answer.length <= 2) {
      setAnswer(() => {
        const newAnswer = [...answer]

        if (newAnswer[0] === undefined && newAnswer.indexOf(choice) === -1) {
          newAnswer[0] = choice
        } else if (
          newAnswer.indexOf(choice) === -1 &&
          newAnswer[1] === undefined
        ) {
          newAnswer[1] = choice
        } else if (newAnswer.indexOf(choice) !== -1) {
          newAnswer[newAnswer.indexOf(choice)] = undefined
        }

        return newAnswer
      })
    }
  }

  const failed = () => {
    toast.error("Terjadi kesalahan")
  }

  const handleNext = async () => {
    if (progress?.answer) {
      const oldAnswer = JSON.parse(progress.answer)
      oldAnswer.push(answer)
      const currentAnswer = JSON.stringify(oldAnswer)

      const submit = await updateTestProgress(id, {
        current_number: progress.current_number + 1,
        answer: currentAnswer,
      })
      console.log(submit.error)

      if (submit.status !== 200) {
        failed()
      } else if (oldAnswer.length === 14) {
        navigate(`/personality-test/test-result/${id}`)
      } else {
        setAnswer([])
        setTrigger(!trigger)
      }
    }
  }

  const handlePrevious = async () => {
    if (progress?.answer) {
      const oldAnswer = JSON.parse(progress.answer)
      oldAnswer.pop()
      const currentAnswer = JSON.stringify(oldAnswer)
      const submit = await updateTestProgress(id, {
        current_number: progress.current_number - 1,
        answer: currentAnswer,
      })

      if (submit.status !== 200) {
        failed()
      } else {
        setAnswer([])
        setTrigger(!trigger)
      }
    }
  }

  function checkNumber(index) {
    if (answer?.includes(index + 1)) {
      return answer.indexOf(index + 1) + 1
    }
  }

  return (
    <Layout>
      {user?.email && progress?.finished === false ? (
        <PersonalityTestStyles>
          <div className="box">
            <div className="ongoingContent">
              <div className="crop">
                <div className="titleOnGoing">
                  <div style={{ fontWeight: "bold" }}>
                    Kuis Kepribadian John Oldham <br />
                    Bahasa Indonesia
                  </div>
                  <Button
                    to={"/personality-test"}
                    className="buttonBack"
                    style={{ position: "absolute" }}
                  >
                    Kembali
                  </Button>
                </div>

                <img
                  src={background}
                  alt="bg"
                  height={10}
                  className="imgOngoing"
                />
              </div>

              <div className="choices">
                <div className="titlePertanyaan">
                  Silahkan pilih <strong>dua</strong> pernyataan dibawah ini
                  yang paling sesuai dengan dirimu.
                </div>

                <div className="counter">
                  <ProgressBar
                    completed={
                      progress?.current_number ? progress.current_number : 0
                    }
                    maxCompleted={14}
                    isLabelVisible={false}
                    bgColor="#EFA51D"
                    height="10px"
                  />
                  <div className="counter">{progress?.current_number}/14</div>
                </div>

                {progress?.choices.map((question, index) => (
                  <div key={index}>
                    <CheckBox
                      onClickfunc={() => handleOnClick(index)}
                      number={checkNumber(index)}
                    >
                      {question}
                    </CheckBox>
                  </div>
                ))}

                <div className="buttonNavi">
                  {progress?.current_number !== 1 ? (
                    <Button className="buttonStyle" onClick={handlePrevious}>
                      <div>
                        <img src={previous} alt="previous" />
                      </div>
                      <div>PREVIOUS</div>
                    </Button>
                  ) : (
                    <Button
                      disabled
                      className="buttonStyle"
                      onClick={handlePrevious}
                    >
                      <div>
                        <img src={previous} alt="previous" />
                      </div>
                      <div>PREVIOUS</div>
                    </Button>
                  )}

                  {answer[0] !== undefined && answer[1] !== undefined ? (
                    <Button className="buttonStyle" onClick={handleNext}>
                      <div>
                        {progress?.current_number === 14 ? "FINISH!" : "NEXT"}
                      </div>
                      {progress?.current_number === 14 ? (
                        ""
                      ) : (
                        <div>
                          <img src={next} alt="next" />
                        </div>
                      )}
                    </Button>
                  ) : (
                    <Button className="buttonStyle" disabled Click={handleNext}>
                      {progress?.current_number === 14 ? (
                        <div>FINISH!</div>
                      ) : (
                        <>
                          {" "}
                          <div>NEXT</div>
                          <div>
                            <img src={next} alt="next" />
                          </div>
                        </>
                      )}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </PersonalityTestStyles>
      ) : (
        <div
          style={{
            width: "100%",
            height: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </Layout>
  )
}

export default withConnect(OngoingTestPage)
